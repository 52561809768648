import React, { ReactElement, useContext } from 'react'
import { COLOR } from '@farewill/ui/tokens'
import { SplitContext } from '@splitsoftware/splitio-react'
import { useRecordSplitPageview } from 'lib/hooks/useRecordSplitPageview'

import DefaultLayout from 'layouts/DefaultLayout'
import JSONLD from 'components/JSONLD'
import FAREWILL_JSONLD from 'lib/json-ld/farewill'
import { useExperiment } from 'lib/hooks/useExperiment'
import useContentfulLandingPageRedirect from './contentfulRedirectHook'
import ContentExperimental from './ContentExperimental'
import Content from './Content'

const HomePage = ({
  pageContext,
}: {
  pageContext: {
    partnerSlugMap: string[]
  }
}): ReactElement => {
  useContentfulLandingPageRedirect(pageContext.partnerSlugMap)
  useRecordSplitPageview('homepage_page_view', undefined, { source: 'direct' })
  const treatment = useExperiment('ExptHomepage')
  const { isReady } = useContext(SplitContext)

  const isExperiment = treatment === 'experiment_variant'
  return (
    <>
      <JSONLD data={FAREWILL_JSONLD} />

      {isReady ? (
        <DefaultLayout
          title="Wills And Probate Experts | The Simpler Cremation Service"
          description="Write a legal will in 15 minutes. Apply for probate or arrange a cremation over the phone."
          headerColor={isExperiment ? COLOR.WHITE : COLOR.ACCENT.PRIMARY}
          headerShowLoginLink
        >
          {isExperiment ? <ContentExperimental /> : <Content />}
        </DefaultLayout>
      ) : (
        <></>
      )}
    </>
  )
}

export default HomePage
