import { graphql, useStaticQuery } from 'gatsby'
import { SPECIALISTS } from 'lib/products/constants'
import { specialistQueries } from './constants'

const useGetSpecialists = (
  group: SPECIALISTS
): GatsbyTypes.ContentfulTeamMemberGroup | [] => {
  if (!specialistQueries[group]) return []

  const specialists = useStaticQuery(graphql`
    query {
      probateSpecialistGroup: contentfulTeamMemberGroup(
        name: { eq: "Probate Specialists" }
      ) {
        ...SpecialistGroupFields
      }

      cremationSpecialistGroup: contentfulTeamMemberGroup(
        name: { eq: "Cremation Specialists" }
      ) {
        ...SpecialistGroupFields
      }

      legal: contentfulTeamMemberGroup(name: { eq: "Legal" }) {
        ...SpecialistGroupFields
      }

      willsSpecialistGroup: contentfulTeamMemberGroup(
        name: { eq: "Wills Specialists" }
      ) {
        ...SpecialistGroupFields
      }

      telephoneWillsSpecialistGroup: contentfulTeamMemberGroup(
        name: { eq: "Telephone Wills Specialists" }
      ) {
        ...SpecialistGroupFields
      }

      funeralCoordinators: contentfulTeamMemberGroup(
        name: { eq: "Funeral Coordinators" }
      ) {
        ...SpecialistGroupFields
      }

      funeralCelebrants: contentfulTeamMemberGroup(
        name: { eq: "Funeral Celebrants" }
      ) {
        ...SpecialistGroupFields
      }

      funeralAwards: contentfulTeamMemberGroup(name: { eq: "Funeral Awards" }) {
        ...SpecialistGroupFields
      }

      crossTeam: contentfulTeamMemberGroup(name: { eq: "Cross Team" }) {
        ...SpecialistGroupFields
      }
    }
  `)
  const {
    teamMembers,
  }: { teamMembers: GatsbyTypes.ContentfulTeamMemberGroup } =
    specialists[specialistQueries[group]]

  return teamMembers
}

export default useGetSpecialists
