import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import { PRODUCTS_MAP, PRODUCTS } from 'lib/products/constants'
import { PRODUCT_LINKS_MAP } from 'lib/navigation/constants'

import {
  CREMATION_FUNERAL_PLAN_FEE,
  ONLINE_WILL_PRICE_IN_POUNDS,
  CREMATION_SIMPLE_FEE,
} from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'

import ProductCard from './ProductCard'

const coreProducts = [
  PRODUCTS.WILLS,
  PRODUCTS.PROBATE,
  PRODUCTS.FUNERAL,
  PRODUCTS.FUNERAL_PLAN,
] as [PRODUCTS.WILLS, PRODUCTS.PROBATE, PRODUCTS.FUNERAL, PRODUCTS.FUNERAL_PLAN]

const StyledHomeProducts = styled.ul`
  ${screenMin.m`
    display: grid;
    grid: 1fr / repeat(2, 1fr);
    gap: ${GTR.M};
  `}

  ${screenMin.xl`
    grid-template-columns: repeat(4, 1fr);
    gap: ${GTR.M};
  `}

  & li {
    margin-bottom: ${GTR.M};

    ${screenMin.l`


      margin-bottom: 0;

    `}
  }
`

const StyledProductCard = styled(ProductCard)`
  height: 100%;
`

/** As we are running A/B Test, I dont wnat to overwrite the data used in PRODUCTS_MAP, we can do it,
 * when we know that experiment is successful and we want to keep the changes.
 */
const experimentalContent = {
  [PRODUCTS.WILLS]: {
    ...PRODUCTS_MAP[PRODUCTS.WILLS],
    description: `Write your will online or over the phone, from ${formatPriceInPounds(
      ONLINE_WILL_PRICE_IN_POUNDS
    )}.`,
    illustration: 'blob-signing-will-2',
  },
  [PRODUCTS.PROBATE]: {
    ...PRODUCTS_MAP[PRODUCTS.PROBATE],
    illustration: 'farewill-probate',
  },
  [PRODUCTS.FUNERAL]: {
    ...PRODUCTS_MAP[PRODUCTS.FUNERAL],
    description: `A direct cremation for a more personal goodbye, from ${formatPriceInPounds(
      CREMATION_SIMPLE_FEE
    )}.`,
    ctaText: 'Arrange a funeral',
    illustration: 'blob-cremation-urn',
  },
  [PRODUCTS.FUNERAL_PLAN]: {
    ...PRODUCTS_MAP[PRODUCTS.FUNERAL_PLAN],
    description: `Get peace of mind with a funeral plan, just ${formatPriceInPounds(
      CREMATION_FUNERAL_PLAN_FEE
    )}.`,
    illustration: 'lady_with_plan-accent',
  },
}

const HomeProducts = (): ReactElement => (
  <StyledHomeProducts>
    {coreProducts.map((id) => {
      const product = experimentalContent[id]
      const productPath = PRODUCT_LINKS_MAP[id].path

      return (
        <li key={product.title}>
          <StyledProductCard
            title={product.title}
            path={productPath}
            illustration={product.illustration}
            description={product.description}
            ctaText={product.ctaText}
            product={id}
          />
        </li>
      )
    })}
  </StyledHomeProducts>
)

export default HomeProducts
