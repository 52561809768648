import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button, Image, H, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR, BORDER_RADIUS } from '@farewill/ui/tokens'
import { screen, screenMin } from '@farewill/ui/helpers/responsive'

const StyledProductCard = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: solid 1px ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER_RADIUS.S};

  ${screenMin.l`
    display: flex;
    justify-content: space-between;
    gap: ${GTR.M};
  `}

  ${screenMin.xl`
    display: block;
  `}
`

const StyledImageWrapper = styled(Wrapper)`
  position: relative;
  max-width: 145px;
  left: 50%;
  transform: translateX(-50%);

  ${screen.m`
    left: 0;
    transform: translateX(0);
  `}

  ${screenMin.l`
  left: 0;
    transform: translateX(0);
    flex-basis: 50%;
    width: 50%;

    img {
      max-width: 90%;
    }
  `}

  ${screenMin.l`
  max-width: 190px;
  `}

    ${screenMin.xl`
    width: auto;
    `}

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }
`

const StyledIllustration = styled(Image)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  max-width: 190px;
  max-height: 145px;
  object-fit: contain;
`

const StyledContentWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`

type ProductCardProps = {
  className?: string
  title: string
  path: string
  illustration?: string
  description?: string
  ctaText?: string
  product?: string
}

const ProductCard = ({
  className,
  title,
  path,
  illustration,
  description,
  ctaText,
  product,
}: ProductCardProps): ReactElement => (
  <StyledProductCard
    className={className}
    container
    paddingFromM={['L', 'L']}
    paddingFromL="M"
  >
    <StyledImageWrapper>
      <StyledIllustration
        path={`e_trim/illustrations/${illustration}`}
        width={380}
        height={300}
      />
    </StyledImageWrapper>
    <StyledContentWrapper noTrim>
      <Wrapper margin={['M', 0, 'M', 0]} marginFromL={[0, 0, 'M', 0]}>
        <H size="S" margin={[0, 0, 'XS']}>
          {title}
        </H>
        <P size="S">{description}</P>
      </Wrapper>
      <Button.Primary
        tag={Link}
        to={path}
        data-track-event="click"
        data-track-element="cta"
        data-track-slice="product-card"
        data-track-product={product}
        stretch
      >
        {ctaText}
      </Button.Primary>
    </StyledContentWrapper>
  </StyledProductCard>
)

export default ProductCard
