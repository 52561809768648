import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  H,
  Grid,
  Wrapper,
  BackgroundImageWrapper,
  Button,
  ArrowRightIcon,
  P,
} from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'
import BackgroundImage from '@farewill/ui/components/Image/BackgroundImageWrapper'

import { ROUTES } from 'views/cremation/routes'
import { FF_FUNERAL_PLANS_TV_AD_ENABLED, NOTICE_BANNER_ENABLED } from 'config'
import AspectRatioWrapper from 'components/AspectRatioWrapper'
import PreventOrphan from 'components/PreventOrphan'

import TrustpilotSummary from 'components/TrustpilotWidget/TrustpilotSummary'
import { PRODUCTS } from 'lib/products/constants'

const StyledTitle = styled(H)`
  ${screenMax.m`
    font-size: ${FONT.SIZE.XXL};
  `}

  ${screenMax.s`
    font-size: ${FONT.SIZE.XL};
  `}
`

const StyledImageWrapper = styled.div`
  position: relative;
  margin: 0 auto -${GTR.S};
  max-width: 350px;

  ${screenMin.m`
    max-width: 500px;
    margin-bottom: -${GTR.L};
    ${FF_FUNERAL_PLANS_TV_AD_ENABLED ? `padding-top: ${GTR.M}` : ''};
  `}

  ${screenMin.l`
    margin-bottom: -${GTR.XL};
  `}
`

const StyledImage = styled(BackgroundImageWrapper)`
  z-index: 1;
  pointer-events: none;
`

const StyledTVBadge = styled(BackgroundImage)`
  position: absolute;

  width: 80px;
  height: 80px;
  padding: 0.5em;
  font-size: ${FONT.SIZE.S};
  line-height: 1.25;
  color: ${COLOR.BLACK};

  text-align: center;
  display: flex;
  align-items: center;

  ${FF_FUNERAL_PLANS_TV_AD_ENABLED
    ? `
    right: -3%;
    top: 5%;
    transform: rotate(5deg);
    padding-top: 5px;
  `
    : `
    right: 8%;
    top: 12%;
    transform: rotate(-15deg);
    font-weight: ${FONT.WEIGHT.BOLD};
  `}

  ${screenMin.m`
  width: 90px;
  height: 90px;
  ${
    FF_FUNERAL_PLANS_TV_AD_ENABLED
      ? `
    right: 4%;
    top: 8%;
  `
      : `
    right: 12%;
    top: 20%;
  `
  }
    font-size: ${FONT.SIZE.M};
  `}
`

const StyledGrid = styled(Grid)`
  align-items: center;
`

const StyledGridItemContent = styled(Grid.Item)`
  order: 2;

  ${screenMin.m`
    order: 1;
  `}
`

const StyledGridItemImage = styled(Grid.Item)`
  order: 1;

  ${screenMin.m`
    order: 2;
  `}
`

const Hero = (): ReactElement => (
  <>
    <StyledGrid container>
      <StyledGridItemContent spanFromM={6} spanFromL={5}>
        <StyledTitle size="L" margin={[0, 0, 'S']} maxWidth={420} decorative>
          <PreventOrphan>A funeral plan without all the fuss</PreventOrphan>
        </StyledTitle>
        <P size="L" margin={[0, 0, 'M']} color={COLOR.BLACK}>
          Find out why more people are choosing to pre-pay for a direct
          cremation.
        </P>
        <Button.White
          tag={Link}
          to={ROUTES.PUBLIC.FUNERAL_PLANS.PATH}
          margin={['M', 0, 0]}
          icon={ArrowRightIcon}
          iconPosition="right"
          data-track-event="click"
          data-track-element="cta"
          data-track-slice="hero"
          data-track-product={PRODUCTS.FUNERAL_PLAN}
        >
          Get your free funeral plan guide
        </Button.White>
        <Wrapper margin={['L', 0, 0]}>
          <TrustpilotSummary isCompact />
          <P size="S" color={COLOR.BLACK} margin={['XS', 0, 0]} maxWidth={410}>
            Based on reviews from our Farewill Funerals Ltd and Farewill Ltd
            customers
          </P>
        </Wrapper>
      </StyledGridItemContent>
      <StyledGridItemImage spanFromM={6} spanFromL={6} startColumnFromL={7}>
        <StyledImageWrapper>
          <StyledImage
            tag={AspectRatioWrapper}
            ratio={16 / 9}
            imagePath={
              FF_FUNERAL_PLANS_TV_AD_ENABLED
                ? 'illustrations/family-at-the-table'
                : 'illustrations/tv-homepage-hero'
            }
            imageWidth={350}
            imageWidthFromM={500}
            cover
          />
          {!NOTICE_BANNER_ENABLED && (
            <StyledTVBadge
              imageWidth={80}
              imageWidthFromM={90}
              imagePath="textures/blob-white-1"
            >
              As seen on TV
            </StyledTVBadge>
          )}
        </StyledImageWrapper>
      </StyledGridItemImage>
    </StyledGrid>
  </>
)

export default Hero
