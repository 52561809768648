import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Wrapper, H, Divider } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import Charity from 'components/Charity'
import GuidesBanner from 'components/GuidesBanner'
import TrustpilotReviews from 'components/TrustpilotWidget/TrustpilotReviews'
import YellowBanner from 'components/YellowBanner'
import PreventOrphan from 'components/PreventOrphan'

import ProductCards from './ProductCardsExperimental'
import Hero from './HeroExperimental'
import Support from './Support'
import Team from './Team'

const StyledWhiteWrapper = styled(Wrapper)`
  position: relative;
  z-index: 1;
`

const StyledYellowBanner = styled(YellowBanner)`
  & > div {
    padding: 0;
  }
`

const ContentExperimental = (): ReactElement => (
  <>
    <StyledYellowBanner centered={false} textureStyle="C">
      <Hero />
    </StyledYellowBanner>

    <StyledWhiteWrapper background={COLOR.WHITE}>
      <Wrapper container containerPaddingBottom={0}>
        <H
          size="L"
          maxWidthInColumns={7}
          margin={[0, 'auto', 'L']}
          marginFromM={['S', 'auto', 'XL']}
          marginFromL={[0, 'auto', 'XL']}
          decorative
          centeredFromM
        >
          <PreventOrphan>A simpler way to deal with death</PreventOrphan>
        </H>
        <Wrapper margin={[0, 0, 'L']} marginFromL={[0, 0, 'XXL']}>
          <ProductCards />
        </Wrapper>
        <Divider margin={['XL', 0, 'XL']} marginFromL={['XL', 0, 'XXL']} />
        <Team />
        <Wrapper margin={['M', 0]} marginFromM={['XXL', 0, 0]}>
          <TrustpilotReviews />
        </Wrapper>
        <Divider margin={['XL', 0, 0]} marginFromM={['XL', 0, 'XXL']} />
        <Support />
      </Wrapper>

      <Charity />
    </StyledWhiteWrapper>

    <GuidesBanner />
  </>
)

export default ContentExperimental
